<template>
  <div class="main">
    <div class="main__background">
        <img class="main__background--img" src="../../assets/textures/azul.png" alt="paint" />
    </div>
    <div class="main__header">
      <h2 class="main__header--title">MIS FOTOGRAFÍAS</h2>
      <p class="main__header--subtitle">SESIONES FOTOGRÁFICAS</p>
    </div>
    <section class="main__categories">
      <!-- CORPORATIVO -->
      <router-link to="/fotografias/corporativo">
        <categories>
          <template v-slot:img>
            <img class="main__categories--img" src="../../assets/images/Fotografía/Corporativo/10.jpg" alt="">
          </template>
          <template v-slot:text>
            <btn-prim>
              <template v-slot:text>
                CORPORATIVO
              </template>
            </btn-prim>
          </template>
        </categories>
      </router-link>
      <!-- FAMILIA -->
      <router-link to="/fotografias/familia">
        <categories>
          <template v-slot:img>
            <img class="main__categories--img" src="../../assets/images/Fotografía/Familia/38.jpg" alt="">
          </template>
          <template v-slot:text>
            <btn-prim>
              <template v-slot:text>
                FAMILIA
              </template>
            </btn-prim>
          </template>
        </categories>
      </router-link>
      <!-- PRODUCTO -->
      <router-link to="/fotografias/producto">
        <categories>
          <template v-slot:img>
            <img class="main__categories--img" src="../../assets/images/Fotografía/Producto/22.jpg" alt="">
          </template>
          <template v-slot:text>
            <btn-prim>
              <template v-slot:text>
                PRODUCTO
              </template>
            </btn-prim>
          </template>
        </categories>
      </router-link>
      <!-- RETRATO -->
      <router-link to="/fotografias/retrato">
        <categories>
          <template v-slot:img>
            <img class="main__categories--img" src="../../assets/images/Fotografía/Retrato/26.jpg" alt="">
          </template>
          <template v-slot:text>
            <btn-prim>
              <template v-slot:text>
                RETRATO
              </template>
            </btn-prim>
          </template>
        </categories>
      </router-link>
      <!-- GASTRONOMÍA -->
      <router-link to="/fotografias/gastronomia">
        <categories>
          <template v-slot:img>
            <img class="main__categories--img" src="../../assets/images/Fotografía/Gastronomía/105.jpg" alt="">
          </template>
          <template v-slot:text>
            <btn-prim>
              <template v-slot:text>
                GASTRONOMÍA
              </template>
            </btn-prim>
          </template>
        </categories>
      </router-link>
    </section>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import BtnPrim from '../../components/Buttons/BtnPrim.vue'
import Categories from '../../components/Categories.vue'
import Footer from '../../components/Footer.vue';
export default {
  data() {
    return {
     
    }
  },
  components: {
    Categories,
    BtnPrim,
    Footer
  }
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--txt-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 70px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}
.main__categories{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 0px 50px;
  margin-top: -100px;
}
.main__categories--img{
  width: 500px;
  height: 500px;
  object-fit: cover;
}
@media (max-width: 800px){
  .main__header--title{
    font-size: 50px;
  }
  .main__categories--img {
    width: 200px;
    height: 200px;
  }
}
</style>